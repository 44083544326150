import React from "react";
import './Header.css';

export const Header = () =>{
    return(
        <header>
            <label>
                <a className="clinicName" href="/">😺Przychodnia weterynaryjna🐶</a>
            </label>
        </header>
    )
}